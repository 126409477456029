
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { queryClient, requestFn } from '@common/client';
import { withPageRoutingRule } from '@hoc';
import { Landing } from '@modules';
import { GetStaticPropsContext } from 'next';
import { dehydrate } from 'react-query';
const X_SSG_KEY = String(process.env.NEXT_PUBLIC_SSG_KEY);
const prefixUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/api`;
const headers = (locale) => ({
    'accept-language': locale,
    'X-SSG-KEY': X_SSG_KEY,
});
async function getStaticProps({ locale }: GetStaticPropsContext) {
    await Promise.all([
        queryClient.prefetchQuery(['all-services', locale], () => requestFn({ path: 'all-services', method: 'get' }, { prefixUrl, headers: headers(locale) })),
        queryClient.prefetchQuery(['faq', 'personal', locale], () => requestFn({ path: 'faq', method: 'get' }, { prefixUrl, headers: headers(locale) })),
        queryClient.prefetchQuery(['meta-landing', 'meta', 'remit', locale], () => requestFn({ path: 'meta', method: 'get' }, { prefixUrl, headers: headers(locale) })),
        queryClient.prefetchQuery(['continents-landing', locale], () => requestFn({ path: 'all-countries-landing-page', method: 'get' }, {
            prefixUrl,
            searchParams: { filter: true },
            headers: headers(locale),
        })),
    ]);
    return {
        props: {
            dehydratedState: dehydrate(queryClient),
        },
        /* duration 15 minute*/
        revalidate: 15 * 60,
    };
}
function LandingPage() {
    return <Landing />;
}
LandingPage.pageLayoutConfig = {
    pageViewType: 'Both',
};
export default withPageRoutingRule(LandingPage, []);

    async function __Next_Translate__getStaticProps__1961e68869e__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1961e68869e__ as getStaticProps }
  