import { create } from 'zustand';

import { SetStateStore, setStore } from './helper';

interface IFloatingButtonStore {
  isOpenFloatingChatButton: boolean;
  setIsOpenFloatingChatButton: (value: SetStateStore<boolean>) => void;
}

export const useFloatingButtonStore = create<IFloatingButtonStore>((set) => ({
  isOpenFloatingChatButton: false,
  setIsOpenFloatingChatButton: (value) =>
    set((state) => setStore(state, { isOpenFloatingChatButton: value })),
}));
