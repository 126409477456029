import { motion, useInView, Variants } from 'framer-motion';
import { ReactNode, useRef } from 'react';

interface AnimatedSectionProps {
  id: string;
  viewAmount?: number;
  viewOnce?: boolean;
  children: ReactNode;
  className?: string;
}

const cardVariants: Variants = {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
    },
  },
};

function AnimatedSection({
  id,
  viewAmount = 0.3,
  viewOnce = true,
  children,
  className,
}: AnimatedSectionProps) {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, {
    once: viewOnce,
    amount: viewAmount,
  });

  return (
    <section id={id} ref={ref} className={className}>
      <motion.div
        animate={isInView ? 'onscreen' : 'offscreen'}
        variants={cardVariants}
      >
        {children}
      </motion.div>
    </section>
  );
}

export default AnimatedSection;
