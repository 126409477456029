import { SVGProps } from 'react';

function RatingStar({
  type = 'primary',
  fill = 'var(--orange-500)',
  ...props
}: SVGProps<SVGSVGElement> & {
  fill?: string;
  type?: 'primary' | 'gradient' | 'rounded';
}) {
  switch (type) {
    case 'primary': {
      return (
        <svg
          width={14}
          height={14}
          viewBox="0 0 14 14"
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M10.452 8.3535C10.3009 8.49991 10.2315 8.71166 10.2659 8.91933L10.7845 11.7893C10.8283 12.0326 10.7256 12.2787 10.522 12.4193C10.3225 12.5652 10.0571 12.5827 9.83953 12.466L7.25594 11.1185C7.16611 11.0707 7.06636 11.045 6.96428 11.0421H6.80619C6.75136 11.0502 6.69769 11.0677 6.64869 11.0946L4.06453 12.4485C3.93678 12.5127 3.79211 12.5354 3.65036 12.5127C3.30503 12.4473 3.07461 12.1183 3.13119 11.7712L3.65036 8.90125C3.68478 8.69183 3.61536 8.47891 3.46428 8.33016L1.35786 6.2885C1.18169 6.11758 1.12044 5.86091 1.20094 5.62933C1.27911 5.39833 1.47861 5.22975 1.71953 5.19183L4.61869 4.77125C4.83919 4.7485 5.03286 4.61433 5.13203 4.416L6.40953 1.79683C6.43986 1.7385 6.47894 1.68483 6.52619 1.63933L6.57869 1.5985C6.60611 1.56816 6.63761 1.54308 6.67261 1.52266L6.73619 1.49933L6.83536 1.4585H7.08094C7.30028 1.48125 7.49336 1.6125 7.59428 1.8085L8.88869 4.416C8.98203 4.60675 9.16344 4.73916 9.37286 4.77125L12.272 5.19183C12.517 5.22683 12.7218 5.396 12.8029 5.62933C12.8793 5.86325 12.8134 6.11991 12.6337 6.2885L10.452 8.3535Z" />
        </svg>
      );
    }
    case 'gradient': {
      return (
        <svg
          width={18}
          height={17}
          viewBox="0 0 18 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9 0L11.826 5.53493L18 6.49382L13.5723 10.8726L14.5622 17L9 14.1725L3.43778 17L4.42695 10.8726L0 6.49382L6.17396 5.53493L9 0Z"
            fill="#FFDE17"
          />
          <path
            style={{
              mixBlendMode: 'color-burn',
            }}
            d="M9 0L11.826 5.53493L18 6.49382L13.5723 10.8726L14.5622 17L9 14.1725L3.43778 17L4.42695 10.8726L0 6.49382L6.17396 5.53493L9 0Z"
            fill="url(#paint0_linear_10484_68350)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_10484_68350"
              x1={2.96444}
              y1={0.265541}
              x2={17.8256}
              y2={22.5787}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset={1} stopColor="#231F20" />
            </linearGradient>
          </defs>
        </svg>
      );
    }
    case 'rounded': {
      return (
        <svg
          width={20}
          height={20}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M1.10575 10.3342L4.07242 12.5009L2.94575 15.99C2.76368 16.5312 2.76137 17.1167 2.93917 17.6593C3.11698 18.2018 3.46537 18.6724 3.93242 19.0009C4.39146 19.3398 4.94774 19.5214 5.51838 19.5186C6.08901 19.5157 6.64345 19.3286 7.09908 18.985L9.99992 16.85L12.9016 18.9825C13.3598 19.3196 13.9131 19.5026 14.4819 19.5054C15.0507 19.5082 15.6057 19.3305 16.0672 18.9979C16.5287 18.6654 16.8728 18.1951 17.0502 17.6546C17.2275 17.1141 17.2289 16.5313 17.0541 15.99L15.9274 12.5009L18.8941 10.3342C19.3517 9.99961 19.6919 9.52898 19.866 8.98952C20.0402 8.45005 20.0394 7.86934 19.8638 7.33035C19.6882 6.79135 19.3468 6.32163 18.8883 5.98828C18.4298 5.65493 17.8776 5.475 17.3108 5.47419H13.6666L12.5608 2.02752C12.3869 1.48499 12.0451 1.0117 11.5849 0.675911C11.1246 0.340121 10.5696 0.15918 9.99992 0.15918C9.43019 0.15918 8.87519 0.340121 8.41494 0.675911C7.95469 1.0117 7.61298 1.48499 7.43908 2.02752L6.33325 5.47419H2.69242C2.12554 5.475 1.57341 5.65493 1.1149 5.98828C0.65639 6.32163 0.314951 6.79135 0.139351 7.33035C-0.0362483 7.86934 -0.0370256 8.45005 0.13713 8.98952C0.311286 9.52898 0.651467 9.99961 1.10908 10.3342H1.10575Z"
            fill={fill}
          />
        </svg>
      );
    }
  }
}

export default RatingStar;
