import { TransactionCount, TransactionReviewStatus } from '@api-hooks/common';
import {
  ICreateMultipleRecipientQuotationParams,
  MaintenancePayerDetail,
} from '@api-hooks/domestic';
import { RefundRequestsModel } from '@api-hooks/refund';
import { ApiMeta, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import {
  AnnouncementCorridor,
  PaymentStatus,
  RedflagStatus,
  RedflagSuspect,
  RoutingChannelType,
  SoftLimitType,
  TransactionStatus,
  TransactionType,
} from '@topremit/shared-web/api-hooks/transaction';
import { NotesStatus } from '@topremit/shared-web/components/elements';
import { FlagCode } from '@topremit/shared-web/components/elements/Flag';
import { ListActionType } from '@topremit/shared-web/typings/transaction.model';
import { CSSProperties } from 'react';

export enum RemitMode {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export enum VoucherState {
  AIRASIA = 'airasia',
  VOUCHER = 'voucher',
  CHOOSE = 'choose',
}

export interface DynamicFieldOption {
  label: string;
  value: string;
  description: string;
  checked: boolean;
  disabled: boolean;
}

export interface DynamicField {
  type: string;
  name: string;
  label: string;
  required: boolean;
  disabled: boolean;
  showOptionalLabel?: boolean;
  limit?: number;
  isRequestable?: boolean;
  options?: DynamicFieldOption[];
  recipientSegment: 'ALL' | 'BUSINESS' | 'PERSONAL';
  senderSegment: 'ALL' | 'BUSINESS' | 'PERSONAL';
  hitLength?: number;
  hidden?: boolean;
  url?: string;
  searchPlaceholder?: string;
  fields?: string[];
}

export enum TransactionListStatus {
  ALL = '',
  NEED_APPROVAL = 'NEED_APPROVAL',
  NEED_PAYMENT = 'NEED_PAYMENT',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_GOING = 'ON_GOING',
  INCOMPLETE = 'INCOMPLETE',
  SUCCESS = 'SUCCESS',
  IN_REVIEW = 'IN_REVIEW',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  ALL_FINISHED = 'ALL_FINISHED',
  COMPLETED = 'COMPLETED',
  NEED_TRANSFER_RECEIPT = 'NEED_TRANSFER_RECEIPT',
  WAITING_FOR_PICKUP = 'WAITING_FOR_PICKUP',
}

export enum WithdrawalListStatus {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

export enum TransactionListType {
  ALL = '',
  INSTANT_TRANSFER = 'REALTIME_TRANSFER',
  BANK_TRANSFER = 'BANK_ACCOUNT',
  CASH_PICKUP = 'CASH_PICKUP',
  E_WALLET = 'EWALLET',
  MOBILE_TOPUP = 'MOBILE_TOPUP',
}

export enum FailedActionType {
  NEW_REFUND = 'NEW_REFUND',
  REFUND = 'REFUND',
  REFUND_AGAIN = 'REFUND_AGAIN',
  REFUND_DETAILS = 'REFUND_DETAILS',
}

export enum PaymentMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  VIRTUAL_ACCOUNT = 'VIRTUAL_ACCOUNT',
}

export enum DiscountType {
  DISCOUNT_PERCENTAGE_FEE = 'DISCOUNT_PERCENTAGE_FEE',
  DISCOUNT_PERCENTAGE_PAYMENT = 'DISCOUNT_PERCENTAGE_PAYMENT',
  DISCOUNT_VALUE_FEE = 'DISCOUNT_VALUE_FEE',
  DISCOUNT_VALUE_PAYMENT = 'DISCOUNT_VALUE_PAYMENT',
}

export enum TransactionDirection {
  DOMESTIC = 'DOMESTIC',
  OUTBOUND = 'OUTBOUND',
  INBOUND = 'INBOUND',
  AIRTIME = 'AIRTIME',
}

export interface DynamicFormSection {
  title: string;
  fields: DynamicField[];
  note?: string[];
  businessNote?: string;
  section?: string;
}

export interface DynamicForm {
  sections: DynamicFormSection[];
  notes?: string[];
}

export interface Currency {
  isoCode: string;
  defaultReceiveAmount: number;
  defaultSendAmount: number;
}

export interface AirtimeModel {
  country: string;
  destinationCurrency: string;
  fee: string;
  msisdn: string;
  note: string;
  operator: string;
  products: AirtimeProduct[];
  sourceCurrency: string;
}

export interface AirtimeProduct {
  id: string;
  name: string;
  price: string;
  totalPrice: string;
}

export interface Airtime {
  recipientId: string;
  refId: string;
}

export interface DontShowLimitReminderParam {
  type: SoftLimitType;
}

export interface AirtimeParam {
  msisdn: string;
  product: string;
}

export interface TransactionTrackerDetail {
  subject: string;
  value: string;
  flagCode?: FlagCode;
  currency?: string;
}

export interface LogDetail {
  status: string;
  statusDesc: string;
  statusCode: TransactionStatus;
  date: string;
}
export interface TransactionLog {
  additionalInfo?: string;
  details: LogDetail[];
  estimationArrivalTime?: string;
  etaNote?: string;
  lastStep: string;
  status: string;
  transactionStatus: TransactionStatus;
  announcements: AnnouncementCorridor;
}
export interface TransactionTrackerModel {
  upperNoteInfo?: string;
  transactionDetails: TransactionTrackerDetail[];
  transactionLog: TransactionLog;
  // eslint-disable-next-line @typescript-eslint/ban-types
  direction: TransactionDirection;
  trackHighlight: string;
}

export interface QuotationForm {
  form: {
    segment: DynamicForm;
    recipientForm: DynamicForm;
    additionalForm: DynamicForm;
  };
}
export interface Styles {
  color?: string;
  fontWeight?: string;
}
// later, change to PaymentDetailList
export interface AmountDetailList {
  name: string;
  label: string;
  value: string;
  iconFlag?: FlagCode;
  canCopy?: boolean;
  tooltipLabel?: string;
  valueStyles?: CSSProperties;
  labelStyles?: CSSProperties;
}
export interface AdditionalDetailList {
  name: string;
  label: string;
  value: string;
}
export interface AdditionalDetail {
  listData: AdditionalDetailList[];
}
export interface TransactionDetailInfo {
  transactionNote?: {
    message: string;
    type: NotesStatus;
  };
  externalId?: ExternalId;
  listData: AmountDetailList[][];
}
export interface RecipientDetail {
  name?: string;
  msisdn?: string;
  segment?: string;
  provider?: string;
  flagCode?: FlagCode;
  payerLogo?: string;
  payerAccount?: string;
  accountNumber?: string;
  listData?: AdditionalDetailList[];
  payerId?: number;
  quotationId?: string;
  recipientId?: number;
  fee?: string;
  sentAmount?: string;
  discount?: string;
  feeDiscount?: string;
  sentAmountValue: number;
  feeValue?: number;
  totalToPay: number;
  refId: string;
  statusMessage: string;
  status: TransactionStatus;
  statusShort: string;
  failedAction?: FailedActionType;
  failedReason?: string[];
  latestRefundRequestId: string;
  redflagStatus?: RedflagStatus;
  redflagSuspect?: RedflagSuspect;
  actions?: string[];
  quotationTotalToPay: string;
  normalFee?: string;
  discountFee?: {
    discountValue: number;
    validUntil: string;
  };
  voucherDiscount?: string;
  receivedAmount?: string;
  payerName?: string;
}

export interface ExternalId {
  name: string;
  value: string;
  note: string;
  canCopy: boolean;
}

export type AmountDetail = {
  serviceId: number;
  destinationAmount: string;
  topupAmount: string;
  service: string;
  listData: AmountDetailList[][];
  serviceIcon: string;
  /**
   * for now only available in SWIFT service
   */
  transactionNote?: {
    message: string;
    type: 'warning';
  };
  externalId?: ExternalId;
};

export interface PaymentDetail {
  recipientName: string;
  listData: AmountDetailList[][];
}

export type TransactionDetailType =
  | TransactionType.REMIT
  | TransactionType.AIRTIME;
export interface PaymentDetailInfo {
  status: PaymentStatus;
  expirationDate: string;
  type: PaymentMethod;
  bank: string;
  // bankCode will be undefined on Virtual Account
  bankCode?: string;
  // bank logo image url
  imageUrl: string;
  dealingCode: string;
  accountNo: string;
  accountName: string;
  amount: number;
  amountLabel: string;
  receiptUploaded: boolean;
  createdAt: string;
  batchId: string;
  isBatch: boolean;
  isDomestic: boolean;
}

export interface TransactionDetail {
  id: number;
  reviewStatus: TransactionReviewStatus;
  dealingCode: string;
  destinationAmount: number;
  destinationCurrency: string;
  destinationCountry: string;
  destinationCountryFlagCode: string;
  pickupExpirationNote: string;
  createdAt: string;
  refId: string;
  status: TransactionStatus;
  statusMessage: string;
  type: TransactionType;
  expirationDate: string;
  actions: ListActionType[];
  airasiaPoints: number;
  statusShort: string;
  estimationArrivalTime: string;
  amountDetail: AmountDetail;
  recipientDetail: RecipientDetail;
  additionalDetail: AdditionalDetail;
  paymentDetail: PaymentDetail;
  refundRequest?: RefundRequestsModel;
  correspondentFee?: number;
  paymentProofs?: Attachment[];
  isPaymentProofRequired: boolean;
  redflagStatus: RedflagStatus;
  redflagSuspect: RedflagSuspect;
  totalToPay: string;
  etaNote?: string;
  failedAction?: FailedActionType;
  transactionDetail?: TransactionDetailInfo;
  failedReason?: string[];
  isDomestic?: boolean;
  discountFee?: {
    discountValue: number;
    validUntil: string;
  };
  normalFee?: string;
  reviewRate?: number;
  isBatch?: boolean;
  recipientCountData: RecipientCount;
  paymentProofsDetail: PaymentProofDetail;
  discount?: string;
  recipientId: number;
  serviceId: number;
  routingChannel: RoutingChannelType;
  /**
   * Business only
   */
  releaser?: string;
  /**
   * Business only
   */
  maker?: string;
  /**
   * Is payment already selected
   * for the transaction
   */
  isPaymentCreated: boolean;
  /**
   * recreate airtime purpose
   */
  msisdn?: string;
  product?: string;
  isBankPayment: boolean;
  /**
   * used for recreate transaction action data
   */
  recreateAction: RecreateAction;
  transactionDocument?: Attachment;
}

export interface RecreateAction {
  destinationCountry: string;
  destinationCurrency: string;
  destinationAmount: number;
  serviceId: number;
  routingChannel: RoutingChannelType;
  recipientId: number;
  msisdn?: string;
  product?: string;
}

export interface PaymentProofDetail {
  bankName: string;
  accountNumber: string;
  accountHolderName: string;
  bankSourceAccount: 'BANK' | 'OTHER' | '-';
}

export interface TransactionCancel {
  isError: string;
  title: string;
  message: string;
}

export interface ChangeLog {
  status: string;
  statusDesc: string;
  statusCode: TransactionStatus;
  date: string;
}
export interface TransactionTrack {
  etaNote?: string;
  upperNoteInfo?: string;
  additionalInfo?: string;
  changeLogs: ChangeLog[];
  estimationArrivalTime?: string;
  lastStep: string;
  status: string;
  announcements: AnnouncementCorridor;
  recipientName: string;
  receivedAmount: string;
  transactionStatus: TransactionStatus;
}
export interface FailedTransactionList {
  id: number;
  refId: string;
  payerId: number;
  payerName: string;
  payerLogo: string;
  recipientName: string;
  destinationCurrencyIsoCode: string;
  destinationAmount: string;
  flagCode?: FlagCode;
  createdAt: string;
  statusLong: string;
  statusShort: string;
  status: TransactionStatus;
  fee: string;
  accountNumber: string;
  sentAmount: string;
  feeDiscount: string;
  discount: string;
  totalToPay: string;
  failedReason: string[];
  failedAction?: FailedActionType;
  latestRefundRequestId: string;
  receivedAmount: string;
  normalFee?: string;
  discountFee?: {
    discountValue: number;
    validUntil: string;
  };
  isBatch: boolean;
}

/**
 * @deprecated This type is deprecated due to a project merge.
 * Please use `TransactionListPersonal` instead.
 */
export interface TransactionList {
  id: number;
  reviewStatus: TransactionReviewStatus;
  destinationAmount: number;
  destinationCurrency: string;
  destinationCountry: string;
  destinationCountryFlagCode: FlagCode;
  createdAt: string;
  paymentDetail: PaymentDetail;
  refId: string;
  status: string;
  statusMessage: string;
  type: string;
  expirationDate: string;
  actions: string[];
  airasiaPoints: number;
  statusLong: string;
  statusShort: string;
  amount?: number;
  msisdn?: string;
  product?: string;
  redflagStatus?: RedflagStatus;
  redflagSuspect?: RedflagSuspect;
  isBatch: boolean;
  isDomestic: boolean;
  totalFailedTransaction: number;
  refundId: string;
  batchId: string;
}

export interface TransactionListPersonal {
  id: number;
  reviewStatus: TransactionReviewStatus;
  destinationAmount: number;
  destinationCurrency: string;
  destinationCountry: string;
  destinationCountryFlagCode: FlagCode;
  createdAt: string;
  paymentDetail: PaymentDetail;
  refId: string;
  status: string;
  statusMessage: string;
  type: string;
  expirationDate: string;
  actions: string[];
  airasiaPoints: number;
  statusLong: string;
  statusShort: string;
  amount?: number;
  msisdn?: string;
  product?: string;
  redflagStatus?: RedflagStatus;
  redflagSuspect?: RedflagSuspect;
  isBatch: boolean;
  isDomestic: boolean;
}

export interface TransactionListBusiness {
  accountNumber: string;
  additionalFee: number;
  bankName: string;
  createdAt: string;
  description?: string;
  destinationAmount: number;
  destinationCountry: string;
  destinationCountryFlagCode: FlagCode;
  destinationCurrency: string;
  correspondentFee?: number;
  expirationDate: string;
  fee: number;
  recipientName: string;
  redflagStatus: RedflagStatus;
  refId: string;
  sentAmount: number;
  status: TransactionStatus;
  statusMessage: string;
  totalToPay: number;
  uniqueCode: number;
}

interface TransactionListMeta extends ApiMeta, TransactionCount {}

export interface TransactionListResults<
  /**
   * Temporarily set generic type to `TransactionList` to minimize breaking changes.
   * Note: This default will be removed once `TransactionList` deleted.
   */
  T extends TransactionListPersonal | TransactionListBusiness = TransactionList,
> extends Omit<ApiResult<T[]>, 'meta'> {
  counts: TransactionListMeta;
  meta: ApiMeta;
}
export interface TransactionListParam {
  createdAtAfter?: string;
  createdAtBefore?: string;
  status?: string;
  service?: string;
  search?: string;
  type?: string;
  actionGroup?: string;
}

export interface RecipientCount {
  failed: number;
  success: number;
  total: number;
}

export interface QuotationDetail {
  type: string;
  refId: string;
  actions: string[];
  createdAt: string;
  normalFee: string;
  airasiaPoints: number;
  expirationDate: string;
  additionalNotes: string;
  destinationCountry: string;
  destinationCurrency: string;
  dealingCode?: boolean;
  amountDetail: AmountDetail;
  recipientDetail: RecipientDetail;
  discountFee: {
    discountValue: number;
    validUntil: string;
  };
  transactionDetail: TransactionDetailInfo;
  recipientCountData: RecipientCount;
  mode: 'SOURCE_AMOUNT' | 'DESTINATION_AMOUNT';
  serviceId: string;
  routingChannel: string;
  fxRate: number;
  sourceAmount: number;
  destinationAmount: number;
  hasMultiplier: boolean;
  additionalDetail?: AdditionalDetail;
  airasiaEnabled: boolean;
  showPayerMaintenance: boolean;
}

export interface BatchQuotationDetail {
  destinationCurrency: string;
  serviceId: number;
  routingChannel: string;
  createdAt: string;
  destinationAmount: number;
  refId: string;
  type: string;
  actions: string[];
  amountDetail: AmountDetail;
  recipientDetail: RecipientDetail[];
  discountFee?: {
    discountValue: number;
    validUntil: string;
  };
  normalFee?: string;
  maintenancePayers: MaintenancePayerDetail[];
}

export interface Voucher {
  id: number;
  title: string;
  code: string;
  description: string;
  validUntil?: string;
  startAt: string;
  type: DiscountType;
  discountAmount: string;
  discountAmountValue: number;
  discountValue: string;
  maxDiscountAmount: string;
  transactionMinimumAmount: string;
  limit: number;
  dailyLimit: number;
  image?: string;
  termAndConditions: {
    title: string;
    contents: string;
  };
  notes: string;
}

export interface CheckVoucherParam {
  code: string;
  refId: string;
  hasDiscountFee: boolean;
  type: 'APPLY' | 'CHECK';
  isBatch: boolean;
}

export interface CreateTransactionParam {
  bigId?: string;
  quotation: string;
  recipientId: string;
  description: string;
  voucherCode?: string;
  hasDiscountFee?: boolean;
  sender?: { fundSource: number };
  beneficiary: { purposeCode: number };
  direction?: TransactionType.DOMESTIC;
  invoiceFile?: Attachment;
}

export interface DomesticCreateBatchTransactionParam
  extends Omit<
    CreateTransactionParam,
    'quotation' | 'recipientId' | 'sender' | 'beneficiary'
  > {
  batchTransactions: {
    recipientId: number;
    quotation: string;
    beneficiary: {
      purposeCode: number;
    };
  }[];
}

export interface PaymentProvider {
  code: string;
  enable: boolean;
  fasterApproval: boolean;
  imageUrl: string;
  maintenance: boolean;
  maintenanceUntil: string;
  maxLimit: number;
  minLimit: number;
  name: string;
  notes: string;
  showDisabled: boolean;
  transferLimit: string;
}

export interface PaymentMethodsDetail {
  description: string;
  memo: string;
  name: string;
  newLabel: boolean;
  notes: string;
  paymentProviders: PaymentProvider[];
}

export interface PaymentMethodsModel {
  bankTransfer: PaymentMethodsDetail;
  virtualAccount?: PaymentMethodsDetail;
  lastSelected: string | null;
}

export interface GeneralInfo {
  isWorkingHour: boolean;
  skipPaymentInfo: boolean;
}

export interface MakePaymentParam {
  paymentProvider: string;
  transactionRefId: string;
}

export interface MakePayment {
  isDiscountFeeInvalid: boolean;
  discountFeeInvalidMessage?: string;
}

export interface MakePaymentBusinessParam {
  // pinCode used when payment is not bank only
  pinCode?: string;
  useWallet: boolean;
  paymentProvider: string | null;
}

export interface Beneficiary {
  id: number;
  createdAt: string;
  updatedAt: string;
  country: string;
  firstName: string;
  lastName: string;
  idType: string;
  idNumber: string;
  contactNumber: string;
  address: string;
  city: string;
  postalCode: string;
  email: string;
  province: string;
  regency: string;
  purposeCode: string;
  relationship: string;
  deletedAt: string;
  segment: string;
  birthDate: string;
  gender: string;
  nationality: string;
  occupation: string;
  idCountry: string;
  idExpiry: string;
  fullName: string;
  addressDetail: string;
  providerExternalID: string;
}
export interface Sender {
  id: string;
  memberId: number;
  firstName: string;
  lastName: string;
  idType: string;
  idNumber: string;
  fundSource: string;
  birthDate: string;
  country: string;
  updatedAt: string;
  createdAt: string;
  paymentMethod: string;
}

export interface CreditParty {
  id: number;
  createdAt: string;
  updatedAt: string;
  accountNumber: string;
  bankProvince: string;
  bankCity: string;
  bankBranchName: string;
  bankBranchCode: string;
  deletedAt: string;
  accountType: string;
  accountNumberType: string;
  intermediaryBankBranchCode: string;
}

export interface RoutingChannel {
  /**
   * use only when transaction DOMESTIC
   */
  payerId?: number;
  /**
   * use only when transaction DOMESTIC
   */
  serviceId?: number;
  /**
   * use only when transaction DOMESTIC
   */
  recipientId?: number;
  type: RoutingChannelType;
  minLimit: number;
  limit: number;
  isMaintenance: boolean;
}

export interface Recipient {
  id: number;
  payerId: number;
  creditPartyId: number;
  serviceId: number;
  beneficiaryId: number;
  destinationCountry: string;
  destinationCurrency: string;
  updatedAt: string;
  bankProvince: string;
  bankCity: string;
  creditParty: CreditParty;
  beneficiary: Beneficiary;
  bankAccountInfo?: string;
  routingChannels?: RoutingChannel[];
  sender: Sender;
  invoiceNumber?: string;
  purposeLimit?: number | null;
  payerName: string;
  fullName?: string;
}

export interface PaymentConfirmation {
  transactionId: number;
  transferAmount: string;
  transferAt: string;
}

export interface FindByCode {
  id: string;
  name: string;
}

export interface Attachment {
  name?: string;
  fileName?: string;
  url?: string;
  type?: string;
  size?: number;
}

export interface UploadPaymentProofModel {
  attachments: Attachment[];
}

export interface ShowEta {
  showEtaCoachmark: boolean;
}

export interface Rate {
  fxRate: number;
  createdAt: string;
}

export interface IRecipientBatches {
  amount: number;
  id: number;
}

export interface ICreateBulkQuotationParams {
  destinationCountry: string;
  destinationCurrency: string;
  direction: 'OUTBOUND';
  isSendAmount: boolean;
  currentRate: string;
  recipientBatches: IRecipientBatches[];
  routingChannel: string;
  serviceId: number;
  totalAmount: number;
}

export type CreateBulkQuotationParams =
  | ICreateBulkQuotationParams
  | ICreateMultipleRecipientQuotationParams;

export interface CreateBatchTransactionParam {
  direction: 'OUTBOUND';
  description?: string;
  quotationBatchId: string;
  hasDiscountFee: boolean;
  voucherCode: string;
}

export interface ReceiptResponse {
  fileName: string;
  blob: Blob;
}

export interface InvoiceResponse extends ReceiptResponse {}

export interface MassTransactionsActionResponse {
  fail: number;
  success: number;
}

export interface TransactionTrackerParam {
  refId: string;
  token: string;
  name?: string;
}
