import { Easing } from '@topremit/shared-web/components/elements';
import { ReactNode, ReactElement } from 'react';

export enum StepBehaviorType {
  NEXT = 'NEXT',
  PREV = 'PREV',
}

export interface IAnimation {
  stepBehavior?: StepBehaviorType;
  direction?: 'x' | 'y';
  animation?: 'fade' | Easing;
  transitionDuration?: number;
}

type VariantCallback = (
  props: Omit<IAnimation, 'animation' | 'transitionDuration'>,
) => object;

type VariantItemType = object | VariantCallback;
export interface ICustomVariant {
  enter: VariantItemType;
  center: VariantItemType;
  exit: VariantItemType;
}
export interface ISlideShowProps {
  /**
   * @param {boolean} [autoPlay] false
   */
  autoPlay?: boolean;
  /**
   * @param {number} [autoPlayTIme] 2000
   */
  autoPlayTime?: number;
  contentClassName?: string;
  /**
   * @param {boolean} [repeatStep] false
   */
  repeatStep?: boolean;
  /**
   * @param {'x' | 'y'} [direction] x
   */
  direction?: 'x' | 'y';
  /**
   * @param {boolean} [draggable] false
   */
  draggable?: boolean;
  className?: string;
  nextButton?: HTMLElement;
  prevButton?: HTMLElement;
  showStep?: boolean;
  totalData: number;
  stepClassName?: string;
  /**
   * @description get local current
   */
  onChange?: (current: number) => void;
  indicator?: boolean;
  indicatorClassName?: string;
  children: ReactNode;
  value?: number;
  /**
   * @description Custom variant for animation
   * @param {ICustomVariant}
   */
  customVariants?: ICustomVariant;
  animation?: Easing | 'fade';
  transitionDuration?: number;
  dragConstraints?: object;
  dragElastic?: number;
  actionButtonPrev?: ReactElement | null;
  actionButtonNext?: ReactElement | null;
  showActionButton?: boolean;
}
