import { defaultQueryResult } from '@api-hooks/common';
import { requestFn } from '@common/client';
import { useQuery as useCustomQuery } from '@hooks';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import {
  ServiceId,
  ServiceMeta,
  ServiceModel,
  TransactionMode,
} from '@topremit/shared-web/api-hooks/common';
import {
  AnnouncementCorridor,
  AvailableServicesModel,
  CountriesCurrenciesModel,
  DealRateQuotationModel,
  FAQModel,
  GetQuotationSearchParam,
  RemitQuotationModel,
  RoutingChannelType,
  TransactionRecipientType,
} from '@topremit/shared-web/api-hooks/transaction';
import { DEFAULT_TABLE_LIMIT } from '@topremit/shared-web/common/constants';
import {
  callAllFunctions,
  encryptString,
  getCurrentDate,
} from '@topremit/shared-web/common/helper';
import { useNotification } from '@topremit/shared-web/hooks';
import { UseQueryOptions } from 'react-query';

import {
  AirtimeModel,
  BatchQuotationDetail,
  FindByCode,
  GeneralInfo,
  InvoiceResponse,
  PaymentDetailInfo,
  PaymentMethodsModel,
  QuotationDetail,
  QuotationForm,
  Rate,
  ReceiptResponse,
  Recipient,
  TransactionDetail,
  TransactionList,
  TransactionListBusiness,
  TransactionListParam,
  TransactionListPersonal,
  TransactionListResults,
  TransactionTrack,
  Voucher,
} from './transaction.model';

const RATE_TIMEOUT = 10 * 60 * 1000;

export function useGetCountriesCurrencies(
  params?: {
    transactionType?: TransactionMode;
    clientAbadRegistration?: boolean;
    serviceId?: ServiceId;
    currency?: string;
  },
  options?: UseQueryOptions<ApiResult<CountriesCurrenciesModel[]>, ApiError>,
) {
  const { transactionType, clientAbadRegistration, serviceId, currency } =
    params || {};

  return useCustomQuery<CountriesCurrenciesModel[]>(
    ['countries-currencies', options?.queryKey],
    async () =>
      await requestFn(
        { path: 'v2/countries-currencies', method: 'get' },
        {
          searchParams: {
            ...(transactionType && { transactionType }),
            ...(clientAbadRegistration && { clientAbadRegistration }),
            ...(serviceId && { serviceId }),
            ...(currency && { currency }),
          },
        },
      ),
    { ...options },
  );
}

export function useGetAvailableServices(
  options?: UseQueryOptions<ApiResult<AvailableServicesModel[]>, ApiError>,
) {
  return useCustomQuery<AvailableServicesModel[]>(
    ['all-services'],
    async () => await requestFn({ path: 'all-services', method: 'get' }),
    { ...options },
  );
}

export function useGetFAQ(
  params?: {
    isBusiness?: boolean;
    serviceId?: ServiceId;
    currency?: string;
    type?: 'swift';
  },
  options?: UseQueryOptions<ApiResult<FAQModel[]>, ApiError>,
) {
  return useCustomQuery<FAQModel[]>(
    ['faq', params?.type || params?.isBusiness || 'personal'],
    async () =>
      await requestFn(
        {
          path: 'faq',
          method: 'get',
        },
        {
          searchParams: {
            ...(params?.isBusiness && { isBusiness: params?.isBusiness }),
            ...(params?.serviceId && { serviceId: params?.serviceId }),
            ...(params?.currency && { currency: params?.currency }),
          },
        },
      ),
    { enabled: false, ...options },
  );
}

export function useGetServices(
  {
    destinationCountry,
    destinationCurrency,
    transactionType,
    key,
    serviceId,
  }: {
    destinationCountry: string;
    destinationCurrency: string;
    transactionType?: TransactionMode;
    key?: string;
    serviceId?: ServiceId;
  },
  options?: UseQueryOptions<ApiResult<ServiceModel[], ServiceMeta>, ApiError>,
) {
  return useCustomQuery<
    ApiResult<ServiceModel[], ServiceMeta>,
    ApiError,
    ApiResult<ServiceModel[], ServiceMeta>
  >(
    [
      'services',
      key,
      destinationCountry,
      destinationCurrency,
      transactionType,
      serviceId,
    ],
    async () =>
      await requestFn(
        { path: 'services', method: 'get' },
        {
          searchParams: {
            destinationCountry,
            destinationCurrency,
            ...(transactionType && { transactionType }),
            ...(serviceId && { serviceId }),
          },
        },
      ),
    { enabled: false, ...options },
  );
}

export function useGetLiveRate(
  {
    serviceId,
    destinationCountry,
    destinationCurrency,
    amount,
    isSendAmount,
    routingChannel,
    currentRate,
    useDealingCode,
  }: GetQuotationSearchParam,
  key: string,
  options?: UseQueryOptions<ApiResult<RemitQuotationModel>, ApiError>,
) {
  return useCustomQuery<RemitQuotationModel>(
    ['live-rate', key],
    async () =>
      await requestFn(
        { path: 'rate/live', method: 'get' },
        {
          searchParams: {
            amount,
            serviceId,
            isSendAmount,
            routingChannel,
            destinationCountry,
            destinationCurrency,
            ...(currentRate && { currentRate }),
          },
          headers: {
            'X-REQ-KEY': String(
              encryptString(
                getCurrentDate(),
                String(process.env.NEXT_PUBLIC_SECRET_KEY),
              ),
            ),
          },
        },
      ),
    {
      enabled: serviceId !== -1 && !useDealingCode,
      refetchInterval: serviceId === -1 ? false : RATE_TIMEOUT,
      refetchOnWindowFocus: false,
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

export function useGetCorridorAnnouncement(
  {
    destinationCountry,
    destinationCurrency,
    serviceId,
    routingChannel,
  }: {
    destinationCountry: string;
    destinationCurrency: string;
    serviceId: number;
    routingChannel: string;
  },
  options?: UseQueryOptions<ApiResult<AnnouncementCorridor>, ApiError>,
) {
  return useCustomQuery<AnnouncementCorridor>(
    [
      'corridor-announcements',
      destinationCountry,
      destinationCurrency,
      serviceId,
    ],
    async () =>
      await requestFn(
        { path: 'corridor-announcements', method: 'get' },
        {
          searchParams: {
            destinationCountry,
            destinationCurrency,
            serviceId,
            routingChannel,
          },
        },
      ),
    {
      enabled: false,
      ...options,
    },
  );
}

export function useGetQuotationForm(
  refId: string,
  options?: UseQueryOptions<ApiResult<QuotationForm>, ApiError>,
  params?: { isNonTransaction?: boolean },
) {
  return useCustomQuery<QuotationForm>(
    ['quotations', 'quotation-form', refId, params],
    async () =>
      await requestFn(
        {
          path: `quotations/${refId}/forms/content`,
          method: 'get',
        },
        {
          searchParams: {
            ...(params?.isNonTransaction && {
              isNonTransaction: params.isNonTransaction,
            }),
          },
        },
      ),
    {
      enabled: false,
      ...options,
    },
  );
}

export function useGetAirtimeProduct(
  msisdn: string,
  options?: UseQueryOptions<ApiResult<AirtimeModel>, ApiError>,
) {
  return useCustomQuery<AirtimeModel>(
    ['airtime-products', msisdn],
    async () =>
      await requestFn(
        { path: 'airtime/products', method: 'get' },
        {
          searchParams: {
            msisdn,
          },
        },
      ),
    { enabled: false, ...options },
    {
      hasLang: false,
    },
  );
}

export function useGetTransactionDetail(
  refId?: string,
  options?: UseQueryOptions<ApiResult<TransactionDetail>, ApiError>,
) {
  return useCustomQuery<TransactionDetail>(
    ['transaction-detail', refId, options?.queryKey],
    () =>
      //Need v2
      requestFn(
        { path: `v2/transactions/${refId}`, method: 'get' },
        { headers: { 'X-IS-NEW': 'true' } },
      ),
    {
      enabled: typeof refId === 'string' && !!refId,
      ...options,
    },
  );
}

export function useGetTransactionTrack(
  refId: string,
  options?: UseQueryOptions<ApiResult<TransactionTrack>, ApiError>,
) {
  return useCustomQuery<TransactionTrack>(
    ['transaction-track', refId],
    async () =>
      //Need v2
      await requestFn({
        path: `v2/transactions/${refId}/change-logs`,
        method: 'get',
      }),
    {
      enabled: !!refId,
      ...options,
    },
  );
}

export function useGetQuotationDetail(
  {
    refId,
    recipientId,
    params,
  }: {
    refId?: string;
    recipientId?: string | string[] | number;
    params?: { direction?: string; voucherCode?: string };
  },
  options?: UseQueryOptions<ApiResult<QuotationDetail>, ApiError>,
) {
  return useCustomQuery<QuotationDetail | undefined>(
    ['quotation-detail-recipient', refId, recipientId, params],
    async () => {
      if (!refId || !recipientId) {
        return defaultQueryResult;
      }
      return await requestFn(
        {
          path: `quotations/${refId}/recipients/${recipientId}`,
          method: 'get',
        },
        {
          searchParams: {
            ...params,
          },
        },
      );
    },
    {
      enabled: typeof refId === 'string' && !!refId && !!recipientId,
      ...options,
    },
  );
}

export function useGetBatchQuotationDetail(
  {
    qBatchId,
    params,
  }: {
    qBatchId: string;
    params: {
      voucherCode?: string;
    };
  },
  options?: UseQueryOptions<ApiResult<BatchQuotationDetail>, ApiError>,
) {
  return useCustomQuery<BatchQuotationDetail>(
    ['batch-quotation-detail', qBatchId, params?.voucherCode],
    async () =>
      await requestFn(
        {
          path: `quotation-batch/${qBatchId}/recipients`,
          method: 'get',
        },
        {
          searchParams: {
            ...(!!params?.voucherCode && {
              voucherCode: params.voucherCode,
            }),
          },
        },
      ),
    {
      enabled: false,
      ...options,
    },
  );
}

export function useGetVouchers(
  {
    refId,
    params,
  }: {
    refId: string;
    params?: { isBatch: string };
  },
  options?: UseQueryOptions<ApiResult<Voucher[]>, ApiError>,
) {
  return useCustomQuery<Voucher[]>(
    ['vouchers', refId],
    async () =>
      // Need v2
      await requestFn(
        {
          path: `v2/quotations/${refId}/vouchers`,
          method: 'get',
        },
        {
          searchParams: {
            ...(!!params?.isBatch && {
              ...(params ?? ''),
            }),
          },
        },
      ),
    {
      enabled: !!refId,
      ...options,
    },
  );
}

/**
 * @param refetchOnParamsUpdate This parameter enables automatic refetching of data when the filters parameter changes, eliminating the need for manual triggering.
 * This feature was initially introduced to support the new transaction filters for business.
 * this param will be removed after all transaction filter migrate to use new component
 *
 */
export function useGetTransactions<
  /**
   * Temporarily set generic type to `TransactionList` to minimize breaking changes.
   * Note: This default will be removed once `TransactionList` deleted.
   */
  T extends TransactionListBusiness | TransactionListPersonal = TransactionList,
>(
  {
    page = 1,
    limit = DEFAULT_TABLE_LIMIT,
    params,
  }: {
    page?: number;
    limit?: number;
    params?: TransactionListParam;
  } = {},
  options: UseQueryOptions<TransactionListResults<T>, ApiError> = {},
) {
  const { addNotification } = useNotification();
  const { onError, queryKey, enabled, ...resOption } = options || {};
  const response = useCustomQuery<
    TransactionListResults<T>,
    ApiError,
    TransactionListResults<T>
  >(
    [
      'transactions',
      queryKey
        ? queryKey
        : {
            page,
            limit,
            ...params,
          },
    ],
    async () =>
      await requestFn(
        { path: 'v2/transactions', method: 'get' },
        {
          searchParams: {
            page,
            limit,
            ...(!!params?.search && {
              [`filter[search]`]: params.search ?? '',
            }),
            ...(!!params?.actionGroup && {
              [`filter[action_group]`]: params.actionGroup ?? '',
            }),
            ...(!!params?.createdAtAfter && {
              [`filter[created_at_after]`]: params.createdAtAfter ?? '',
            }),
            ...(!!params?.createdAtBefore && {
              [`filter[created_at_before]`]: params.createdAtBefore ?? '',
            }),
            ...(!!params?.status && {
              [`filter[status]`]: params.status ?? '',
            }),
            ...(!!params?.service && {
              [`filter[service]`]: params.service ?? '',
            }),
            ...(!!params?.type && {
              [`filter[type]`]: params.type ?? '',
            }),
          },
          headers: { 'X-IS-NEW': 'true' },
        },
      ),
    {
      onError: callAllFunctions(onError, ({ message, statusCode }) => {
        if (statusCode !== 403) {
          /** Prevent showing message:
           * "Your account is not verified. Please verify it first"
           * */
          addNotification({ message, type: 'danger' });
        }
      }),
      retry: false,
      enabled,
      ...resOption,
    },
  );
  return response;
}

export function useGetDealingCodeQuotation(
  code: string,
  options?: UseQueryOptions<ApiResult<DealRateQuotationModel>, ApiError>,
) {
  return useCustomQuery<DealRateQuotationModel>(
    [`quotations-dealing-code-${code}`],
    async () =>
      await requestFn({
        path: `quotations/dealing-code/${code}`,
        method: 'get',
      }),
    {
      enabled: false,
      retry: false,
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

//This Api Get only return Success and Error message.
export function useGetAirAsiaMemberId(
  {
    memberId,
  }: {
    memberId: string;
  },
  options?: UseQueryOptions<ApiResult, ApiError>,
) {
  return useCustomQuery<any, ApiError>(
    ['airasia-member', memberId],
    async () =>
      await requestFn(
        {
          path: `airasia/validate-member`,
          method: 'get',
        },
        {
          searchParams: {
            big_id: memberId,
          },
        },
      ),
    {
      enabled: false,
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

export function useGetPaymentMethods(
  refId?: string,
  options?: UseQueryOptions<ApiResult<PaymentMethodsModel>, ApiError>,
) {
  return useCustomQuery<PaymentMethodsModel>(
    ['payment-methods', refId],
    async () =>
      await requestFn(
        {
          path: `transactions/${refId}/payment-methods`,
          method: 'get',
        },
        { headers: { isNew: 'true' } },
      ),
    {
      enabled: typeof refId === 'string' && !!refId,
      ...options,
    },
  );
}

export function useGetPaymentDetail(
  refId?: string,
  options?: UseQueryOptions<ApiResult<PaymentDetailInfo>, ApiError>,
) {
  return useCustomQuery<PaymentDetailInfo>(
    ['payments', refId],
    async () =>
      requestFn({
        path: `v2/transactions/${refId}/payment-details`,
        method: 'get',
      }),
    { enabled: typeof refId === 'string' && !!refId, ...options },
  );
}

export function useGetGeneralInfo(
  options?: UseQueryOptions<ApiResult<GeneralInfo>, ApiError>,
) {
  return useCustomQuery<GeneralInfo>(
    ['general-info'],
    async () =>
      await requestFn({
        path: `general-info`,
        method: 'get',
      }),
    {
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

export function useGetRecipient(
  {
    recipientId,
    searchParams,
  }: {
    recipientId: string;
    searchParams?: { transactionType?: TransactionRecipientType };
  },
  options?: UseQueryOptions<ApiResult<Recipient>, ApiError>,
) {
  return useCustomQuery<Recipient>(
    ['recipient', recipientId],
    async () =>
      await requestFn(
        {
          path: `recipients/${recipientId}`,
          method: 'get',
        },
        {
          searchParams,
        },
      ),
    {
      enabled: String(recipientId) !== '0' && !!recipientId,
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

export function useGetReceipt(
  refId: string,
  options?: UseQueryOptions<ReceiptResponse, ApiError>,
) {
  return useCustomQuery<ReceiptResponse, ApiError, ReceiptResponse>(
    ['transaction-receipt', refId],
    async () =>
      await requestFn(
        {
          path: `transactions/${refId}/receipt-pdf`,
          method: 'get',
        },
        { headers: { 'X-IS-NEW': 'true' } },
      ),
    { retry: false, enabled: !!refId, ...options },
  );
}

export function useGetInvoice(
  refId: string,
  options?: UseQueryOptions<InvoiceResponse, ApiError>,
) {
  return useCustomQuery<InvoiceResponse, ApiError, InvoiceResponse>(
    ['transaction-invoice', refId],
    async () =>
      await requestFn({
        path: `transactions/${refId}/invoice-pdf`,
        method: 'get',
      }),
    { retry: false, enabled: !!refId, ...options },
  );
}

/**
 * Export **Business** transaction history
 */
export function useGetTransactionReport(
  {
    createdAtAfter,
    createdAtBefore,
  }: { createdAtAfter: string; createdAtBefore: string },
  options?: UseQueryOptions<ReceiptResponse, ApiError>,
) {
  return useCustomQuery<ReceiptResponse, ApiError, ReceiptResponse>(
    ['transaction-report'],
    async () =>
      await requestFn(
        {
          path: 'v2/transactions/export',
          method: 'get',
        },
        {
          searchParams: {
            [`filter[created_at_after]`]: createdAtAfter,
            [`filter[created_at_before]`]: createdAtBefore,
          },
        },
      ),
    {
      enabled: false,
      retry: false,
      /**
       * Set no cache to this api for up-to-date report
       */
      cacheTime: 0,
      staleTime: 0,
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

export function useGetPurposeByCode(
  purposeCode: string,
  options?: UseQueryOptions<ApiResult<FindByCode>, ApiError>,
) {
  return useCustomQuery<FindByCode>(
    ['purposes', purposeCode],
    async () => {
      if (!purposeCode) {
        return defaultQueryResult;
      }
      return await requestFn({
        path: `purposes/${purposeCode}`,
        method: 'get',
      });
    },
    { retry: false, enabled: !!purposeCode, ...options },
  );
}

export function useGetFundSourceByCode(
  fundSourceCode: string,
  options?: UseQueryOptions<ApiResult<FindByCode>, ApiError>,
) {
  return useCustomQuery<FindByCode>(
    ['fund-sources'],
    async () => {
      if (!fundSourceCode) {
        return defaultQueryResult;
      }
      return await requestFn({
        path: `fund-sources/${fundSourceCode}`,
        method: 'get',
      });
    },
    { retry: false, enabled: !!fundSourceCode, ...options },
    {
      hasLang: false,
    },
  );
}

export function useGetRatesHistory(
  {
    serviceId,
    destinationCountry,
    destinationCurrency,
    routingChannel,
  }: {
    serviceId?: number | string;
    destinationCountry?: string;
    destinationCurrency?: string;
    routingChannel?: RoutingChannelType;
  } = {},
  options?: UseQueryOptions<ApiResult<Rate[]>, ApiError>,
) {
  return useCustomQuery<Rate[]>(
    ['rates-history', options?.queryKey],
    async () =>
      await requestFn(
        { path: 'rates', method: 'get' },
        {
          searchParams: {
            service: serviceId || '',
            country: destinationCountry || '',
            currency: destinationCurrency || '',
            routingChannel: routingChannel || '',
          },
          headers: {
            'X-REQ-KEY': String(
              encryptString(
                getCurrentDate(),
                String(process.env.NEXT_PUBLIC_SECRET_KEY),
              ),
            ),
          },
        },
      ),
    { enabled: false, ...options },
    {
      hasLang: false,
    },
  );
}

export function useGetQuotation(
  refId?: string,
  options?: UseQueryOptions<ApiResult<RemitQuotationModel>, ApiError>,
) {
  return useCustomQuery<RemitQuotationModel>(
    ['quotation-detail', refId],
    async () => await requestFn({ path: `quotations/${refId}`, method: 'get' }),
    {
      enabled: typeof refId === 'string' && !!refId,
      ...options,
    },
  );
}
